import React, { useEffect, useState, useRef } from 'react'
import { Input, Space, Typography, Slider } from 'antd'

import { highlightRange } from '../shared/utilities'

const highlightStyle = { fontSize: "1.2rem", lineHeight: "1.7rem",
  color: "transparent", border: "3px solid transparent" }

const DataPane = (props) => {
  const { inputRef, seq, selectStart, selectEnd, slider, changeSeqHandler,
      changeSlideHandler, afterChangeSlideHandler, changeSelectHandler,
      mouseDownHandler, isFirefox, textRowMax, limit } = props
  const { TextArea } = Input
  const { Text } = Typography
  const [backdropStyle, setBackdropStyle] = useState({})
  const backdropRef = useRef(null)

  let focusHandler, resizeHandler, scrollHandler = null
  if (isFirefox) {
    const [WIDTH_ADJ_PX, INIT_HT, INIT_MARGIN, MARGIN_PER_HT_PX] = [0.2, 76, -2.35, 0.031]
    useEffect(() => { setBackdropStyle({ overflowY: "scroll" })}, [backdropRef])
    useEffect(() => {
      const { offsetWidth, offsetHeight } = inputRef.current.resizableTextArea.textArea
      inputRef.current.resizableTextArea.handleResize({ offsetWidth, offsetHeight })
    },[inputRef])

    useEffect(() => {
      const newMarginTop = getMarginTop()
      const currentWidth = inputRef.current.resizableTextArea.textArea.offsetWidth
      const currentHeight = inputRef.current.resizableTextArea.textArea.offsetHeight
      setBackdropStyle({ width: currentWidth, height: currentHeight, marginTop: `${newMarginTop}rem`})
    },[seq, selectStart, selectEnd])

    focusHandler = (e) => { highlightRange(seq, -1, -1) }
    resizeHandler = (e) => {
      const newMarginTop = getMarginTop()
      setBackdropStyle({ width: e.offsetWidth - WIDTH_ADJ_PX, marginTop: `${newMarginTop}rem`})
    }
    scrollHandler = (e) => { backdropRef.current.scrollTop = e.target.scrollTop }

    const getMarginTop = () => {
      const inputNode = inputRef.current.resizableTextArea.textArea
      return (INIT_MARGIN - (inputNode.offsetHeight - INIT_HT)*MARGIN_PER_HT_PX)
    }
  }

  const blurHandler = (e) => {
    const inputNode = e.target
    if (isFirefox) {
      highlightRange(seq, selectStart, selectEnd)
      setTimeout(() => { e.target.dispatchEvent(new Event('scroll')) }, 0)
    } else {
      inputNode.select()
      inputNode.setSelectionRange(selectStart, selectEnd)
    }
  }

  return (
    <div className="data-pane">
      <Text className="label-input">
        Paste or type <span>your sequence</span> here (up to {limit} nucleotides):
      </Text>
      <Space>
        <Text className="prime-end five-prime" strong="true">5'</Text>
        <Space>
          <div ref={backdropRef} className="backdrop" style={backdropStyle}>
            <div className="highlights ant-input" style={highlightStyle}></div>
          </div>
          <TextArea
            id="seq-input"
            ref={inputRef}
            onChange={changeSeqHandler}
            onSelect={changeSelectHandler}
            onMouseDown={mouseDownHandler}
            onBlur={blurHandler}
            onFocus={focusHandler}
            onResize={resizeHandler}
            onScroll={scrollHandler}
            value={seq}
            cols={92}
            autoSize={{ minRows: 3, maxRows: textRowMax }}
            spellCheck="false"
          ></TextArea>
        </Space>
        <Text className="prime-end three-prime" strong="true">3'</Text>
      </Space>
      <Text className="label-input">
        Select a portion of your sequence to fold.
      </Text>
      <Slider
        style={{ width: "90%", marginLeft: "1rem", maxWidth: "955px" }}
        range={{ draggableTrack: true }}
        min={0}
        max={seq.length}
        value={[selectStart, selectEnd]}
        onChange={changeSlideHandler}
        onAfterChange={afterChangeSlideHandler}
      />
      <Text className="label-slider">
        <span>Click and drag the bar to scan along your sequence.</span>
      </Text>
    </div>
  )
}

export default DataPane
