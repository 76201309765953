import React, { useState } from 'react'

const DownloadButton = (props) => {
  const { downloadLink } = props

  return (
    <div className="download-button">
      <a download="fold_data.txt" href={downloadLink}>Download RNAFold data file</a>
    </div>
  )
}

export default DownloadButton
