import React from 'react'
import { Space, Typography } from 'antd'

const DialogPane = (props) => {
  const { showMin, showLimit, showLag, dialogMessage, min, limit, lagBound } = props
  const { Title, Text } = Typography

  let messageDisplay = null
  if (dialogMessage === showMin) {
    messageDisplay = (
     <Title type="warning" level={5}>
       Your sequence is shorter than {min} nucleotides;
       a corresponding structure will not display
     </Title>
   )
  } else if (dialogMessage === showLimit) {
    messageDisplay = (
     <Title type="warning" level={5}>
       Your sequence exceeds {limit} nucleotides;
       the displayed structure is for the first {limit} nucleotides
     </Title>
   )
  } else if (dialogMessage === showLag) {
    messageDisplay = (
     <Title type="warning" level={5}>
       Your sequence exceeds {lagBound} nucleotides;
       results may be delayed by several seconds
     </Title>
   )
  }

  return (
    <div className="dialog-pane">
      {messageDisplay}
    </div>
  )
}

export default DialogPane
