import React from 'react'
import { NavLink } from 'react-router-dom';
import { Space, Typography } from 'antd'

const TitleBanner = (props) => {
  const { Title, Text } = Typography

  return (
    <Space className="title-banner" size="large">
      <NavLink to="/" exact>
        <h1 className="title"><i>FlyingFold</i></h1>
      </NavLink>
      <h3 className="subtitle">
        Interactive RNAfold secondary structure determination
      </h3>
      <NavLink to="/about" exact>
        <h3 className="about-link">About</h3>
      </NavLink>
    </Space>
  )
}

export default TitleBanner
