import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import 'antd/dist/antd.css'
import { Spin } from 'antd'

import DataPane from '../components/DataPane'
import DialogPane from '../components/DialogPane'
import ResultsPane from '../components/ResultsPane'
import DownloadButton from '../components/DownloadButton'
import { useUpdateTimer } from '../shared/hooks'
import { cleanSequence, highlightRange, formatDownloadText } from '../shared/utilities'

const testSeq = "AUGCUUCCGGCCUGUUCCCUGAGACCUCAAGUGUGAGUGUACUAUUGAUGCUUCACACCUGGGCUCUCCGGGUACCAGGACGGUUUGAGCAGAU"
const emptyFold = { mfe: null, seq: null, ss: null, svg: null, interval: [null, null] }
const FOLD_LIMIT = parseInt(process.env.FOLD_MAX_LENGTH)
const FOLD_LAG_BOUND = parseInt(process.env.FOLD_LAG_BOUNDARY)
const FOLD_MIN = parseInt(process.env.FOLD_MIN_LENGTH)
const TEXT_ROW_MAX = process.env.TEXT_ROW_MAX
const SEQ_FOLD_DELAY = process.env.SEQ_FOLD_DELAY
const URL = process.env.BACKEND_IP
const PORT = process.env.BACKEND_PORT
const PROTOCOL = process.env.PROTOCOL
const [NONE, SHOW_LIMIT_MSG, SHOW_MIN_MSG, SHOW_LAG_MSG] = [-1, 0, 1, 2]

const FlyingFold = (props) => {
  const [seq, setSeq] = useState(testSeq)
  const [selectStart, setSelectStart] = useState(null)
  const [selectEnd, setSelectEnd] = useState(null)
  const [foldData, setFoldData] = useState(emptyFold)
  const [loading, setLoading] = useState(false)
  const [preventSelectFold, setPreventSelectFold] = useState(false)
  const [dialogMessage, setDialogMessage] = useState(NONE)
  const [downloadLink, setDownloadLink] = useState('')
  const containerRef = useRef(null)
  const updateTimer = useRef(null)
  const inputRef = useRef(null)

  const isFirefox = navigator.userAgent.indexOf("Firefox") > 0

  useEffect(() => {
    if (seq.length > FOLD_LIMIT) {
      setLoading(true)
      setPreventSelectFold(true)
      setDialogMessage(SHOW_LIMIT_MSG)
      useUpdateTimer(updateTimer, () => {
        inputRef.current.resizableTextArea.textArea.setSelectionRange(0,FOLD_LIMIT)
        if (isFirefox) {
          setSelectStart(0)
          setSelectEnd(FOLD_LIMIT)
        }
        getFoldData(0, FOLD_LIMIT)
      }, SEQ_FOLD_DELAY)
    } else if (seq.length < FOLD_MIN) {
      setDialogMessage(SHOW_MIN_MSG)
    } else {
      setLoading(true)
      setPreventSelectFold(true)
      if (seq.length > FOLD_LAG_BOUND) setDialogMessage(SHOW_LAG_MSG)
      else  setDialogMessage(NONE)
      useUpdateTimer(updateTimer, () => { getFoldData() }, SEQ_FOLD_DELAY)
    }
  }, [seq])

  useEffect(() => {
    if (selectStart === null && selectEnd === null) {
      inputRef.current.resizableTextArea.textArea.focus()
    }
    if (!preventSelectFold) foldSelectionBox()
  }, [selectStart, selectEnd])

  useEffect(() => {
    setPreventSelectFold(false)
    setLoading(false)
    downloadUpdate()
  }, [foldData])

  const getFoldData = (start, end) => {
    const param = (start != null && end != null) ? seq.slice(start, end) : seq
    const interval = (start != null && end != null) ? [start, end] : [0, seq.length]
    axios
      //.get(`${PROTOCOL}${URL}:${PORT}/api/${param}/`)
      .get(`${PROTOCOL}${URL}/api/${param}/`)
        .then(response => {
          // console.log(response.data)
          setFoldData({ ...response.data, interval })
        })
        .catch(error => {
          setFoldData(emptyFold)
          console.log(error)
        })
  }

  const foldSelectionBox = () => {
    const boxWidth = selectEnd - selectStart
    if (boxWidth === 0) {
      setDialogMessage(NONE)
    } else if (boxWidth > FOLD_LIMIT) {
      setLoading(true)
      setDialogMessage(SHOW_LIMIT_MSG)
      setPreventSelectFold(true)
      const newSelectEnd = selectStart + FOLD_LIMIT
      inputRef.current.resizableTextArea.textArea.setSelectionRange(selectStart, newSelectEnd)
      useUpdateTimer(updateTimer, () => {
        getFoldData(selectStart, newSelectEnd)
      })
    } else if (boxWidth < FOLD_MIN) {
      setDialogMessage(SHOW_MIN_MSG)
    } else {
      setLoading(true)
      if (boxWidth > FOLD_LAG_BOUND) setDialogMessage(SHOW_LAG_MSG)
      else  setDialogMessage(NONE)
      useUpdateTimer(updateTimer, () => {
        getFoldData(selectStart, selectEnd)
      })
    }
  }

  const changeSeqHandler = (e) => {
    const inputNode = e.target
    const inputText = inputNode.value
    const cleanedSequence = cleanSequence(inputText)
    const offset = cleanedSequence.length - inputText.length
    const cursorPos = inputNode.selectionStart + offset
    Promise.resolve()
      .then(() => { setSeq(cleanedSequence) })
      .then(() => { inputNode.setSelectionRange(cursorPos,cursorPos) })
  }

  const changeSlideHandler = (e) => {
    const [slideStart, slideEnd] = e
    const isBoxDrag = slideEnd - slideStart === selectEnd - selectStart
    inputRef.current.resizableTextArea.textArea.setSelectionRange(slideStart, slideEnd)
    if (!isBoxDrag) setPreventSelectFold(true)
    setSelectStart(slideStart)
    setSelectEnd(slideEnd)
    if (isFirefox) highlightRange(seq, slideStart, slideEnd)
  }

  const afterChangeSlideHandler = (e) => {
    if (isFirefox) {
      const newSelectEnd = selectEnd - selectStart > FOLD_LIMIT ? selectStart + FOLD_LIMIT : selectEnd
      setTimeout(() => {inputRef.current.resizableTextArea.textArea.focus()}, 0)
      setTimeout(() => {inputRef.current.resizableTextArea.textArea.setSelectionRange(selectStart, newSelectEnd)}, 0)
      highlightRange(seq, -1, -1)
    }
    setPreventSelectFold(false)
    foldSelectionBox()
  }

  const changeSelectHandler = (e) => {
    const inputNode = e.target
    const { selectionStart, selectionEnd } = inputNode
    setSelectStart(selectionStart)
    setSelectEnd(selectionEnd)
  }

  const mouseDownHandler = (e) => {
    containerRef.current.onmouseup = clearContainerHandlers
    containerRef.current.onmouseleave = () => {
      changeSelectHandler(e)
      clearContainerHandlers()
    }
  }

  const clearContainerHandlers = () => {
    containerRef.current.onmouseup = null
    containerRef.current.onmouseleave = null
  }

  const downloadUpdate = () => {
    const data = new Blob([formatDownloadText(foldData)], {type: 'text/plain'})
    if (downloadLink !== '') window.URL.revokeObjectURL(downloadLink)
    setDownloadLink(window.URL.createObjectURL(data))
  }

  return (
    <div ref={containerRef}>
      <DataPane
        inputRef={inputRef}
        seq={seq}
        selectStart={selectStart}
        selectEnd={selectEnd}
        changeSeqHandler={changeSeqHandler}
        changeSlideHandler={changeSlideHandler}
        afterChangeSlideHandler={afterChangeSlideHandler}
        changeSelectHandler={changeSelectHandler}
        mouseDownHandler={mouseDownHandler}
        isFirefox={isFirefox}
        textRowMax={TEXT_ROW_MAX}
        limit={Math.min(FOLD_LIMIT, FOLD_LAG_BOUND)}
      />
      <DialogPane
        showMin={SHOW_MIN_MSG}
        showLimit={SHOW_LIMIT_MSG}
        showLag={SHOW_LAG_MSG}
        min={FOLD_MIN}
        limit={FOLD_LIMIT}
        lagBound={FOLD_LAG_BOUND}
        dialogMessage={dialogMessage}
      />
      <Spin spinning={loading} size="large">
        <ResultsPane
          svg={foldData.svg}
          mfe={foldData.mfe}
          foldInterval={foldData.interval}
        />
        <DownloadButton downloadLink={downloadLink} />
      </Spin>
    </div>
  )
}

export default FlyingFold
