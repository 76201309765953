import React from 'react'
import { NavLink } from 'react-router-dom';

const TBI_URL = "https://www.tbi.univie.ac.at/RNA/"
const AboutSection = (props) => {

  return (
    <div>
      <NavLink to="/" exact>
        <h3 className="back">&#x2190; Back</h3>
      </NavLink>
      <div className="about-section">
        <h3 className="about-subtitle">How to Use:</h3>
        <div className="about-text">
          There are several ways to generate an RNA secondary structure from a sequence:
          <ul className="about-list" style={{ marginBottom: 0 }}>
            <li>
              Input sequence into text area<br/>
              - All text entered is automatically capitalized. Only characters representing valid nucleotides (A, C, G, U) will appear (T automatically converts to U).<br/>
              - Upon each change (addition/deletion) to the sequence, a new prediction will be generated.
            </li>
            <li>
              Select sequence in text area<br/>
              - Upon release of the cursor, a new prediction will be generated from the selected sequence.
            </li>
            <li>
              Grab slider knob to adjust selection<br/>
              - Grabbing and sliding the left or right knob will shift the start and end position, respectively, of the current text selection.<br/>
              - Upon release of the cursor, a new prediction will be generated from the selected sequence.
            </li>
            <li>
              Grab slider bar to shift selection<br/>
              - Grabbing and sliding the blue bar (between the knobs) will shift the selection interval while preserving its width.<br />
              - With each shift in position of the selection interval, a new prediction will be generated from the selected sequence.
            </li>
          </ul>
          <p className="about-small" style={{ marginLeft: "1.3rem" }}>
            Note: Delays (&gt;2 sec) in RNA structure prediction may occur when attempting to fold longer sequences (&gt;1250 nt).
          </p>
        </div>
        <div className="about-text">
          For every RNA sequence that is folded, the bottom window will show the following results:
          <ul className="about-list">
            <li>
              Diagram of predicted secondary structure<br />
              - Nucleotide in black circle represents 5' end of folded sequence.
            </li>
            <li>
              Nucleotide positions of folded sequence<br />
              - Start/end positions are relative to 5' end of full sequence in text area.
            </li>
            <li>
              Minimum free energy of predicted secondary structure
            </li>
          </ul>
        </div>
        <h3 className="about-subtitle">Citations:</h3>
        <div className="about-text">
          FlyingFold was developed by the Victor Ambros Lab at the University of Massachusetts Medical School:<br />
          <p className="about-small">
            Univ. of Massachusetts Medical School Biotech 2, Suite 306<br />
            373 Plantation Street<br />
            Worcester, MA 01605
          </p>
        </div>
        <div className="about-text">
          This software uses the <a href={TBI_URL} target="#">ViennaRNA Package</a> to generate RNA secondary structure predictions:<br />
          <p className="about-small">
            Lorenz, Ronny and Bernhart, Stephan H. and Höner zu Siederdissen, Christian and Tafer, Hakim and Flamm, Christoph and Stadler, Peter F. and Hofacker, Ivo L.<br />
            ViennaRNA Package 2.0<br />
            Algorithms for Molecular Biology, 6:1 26, 2011, doi:10.1186/1748-7188-6-26
          </p>
        </div>
      </div>
    </div>
  )
}

export default AboutSection
