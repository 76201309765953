import React, { Fragment } from 'react'
import {Route, Switch, Redirect} from 'react-router-dom';

import FlyingFold from './containers/FlyingFold'
import AboutSection from './components/AboutSection'
import TitleBanner from './components/TitleBanner'

const App = (props) => {
  return (
    <Fragment>
      <TitleBanner />
      <Switch>
          <Route path="/about" exact component={AboutSection} />} />
          <Route path="/" exact component={FlyingFold} />
          <Redirect to="/" />
      </Switch>
    </Fragment>
  )
}

export default App
