import React from 'react'
import { Typography } from 'antd'
import { SvgLoader, SvgProxy } from 'react-svgmt'

import MfeDisplay from './MfeDisplay'

const BASE_COLORS = { "A":"green", "C":"blue", "G":"red", "U":"orange" }
const C_RADIUS = 5.5
const [CX_SHIFT_RATIO, CY_SHIFT_RATIO] = [0.7, 0.7]
const [TX_SHIFT, TY_SHIFT] = [0, 0.1]

const ResultsPane = (props) => {
  const { mfe, svg, foldInterval } = props
  const { Text } = Typography

  const getNewSVG = (rawSvg) => {
    const baseTextRegex = /<text(?:.+)x="(-?[\d\.]+)" y="(-?[\d\.]+)">(\w)<\/text>/g
    const newSvg = rawSvg.replace(baseTextRegex, (_,x0,y0,base) => {
      const cx = parseFloat(x0)+CX_SHIFT_RATIO*C_RADIUS
      const cy = parseFloat(y0)-CY_SHIFT_RATIO*C_RADIUS
      const tx = cx + TX_SHIFT
      const ty = cy + TY_SHIFT
      return "<g>\n"
        + `\t    <circle cx="${cx}" cy="${cy}" r="${C_RADIUS}" fill="${BASE_COLORS[base]}" \/>\n`
        + `\t    <text text-anchor="middle" dominant-baseline="central" x="${tx}" y="${ty}">${base}<\/text>\n`
        + "\t  <\/g>"
    })
    const fivePrimeCircle = (
      <SvgProxy selector="#seq g:first-child circle" style="stroke: black; stroke-width: 1.5;" />
    )
    const polyLine = (
      <SvgProxy selector="g polyline" style="stroke: #888888; stroke-width: 2; fill: none;" opacity="0.7" />
    )
    const pairLines = (
      <SvgProxy selector="line" style="stroke: #aaaaaa; stroke-width: 1; fill: none;" />
    )
    const font = (
      <SvgProxy selector="#seq" style="font: 0.5rem Arial; stroke: white; stroke-width: 0.5;" fill="white" />
    )

    return (
        <SvgLoader svgXML={newSvg}>
          {font}
          {pairLines}
          {polyLine}
          {fivePrimeCircle}
        </SvgLoader>
      )
  }

  const svgImg = svg ? getNewSVG(svg) : null
  return (
    <div className="results-pane">
      <h3 className="results-label">RNAfold MFE structure:</h3>
      <div className="results-display">
        {svgImg}
      </div>
      <MfeDisplay mfe={mfe} foldStart={foldInterval[0]} foldEnd={foldInterval[1]} />
    </div>
  )
}

export default ResultsPane
