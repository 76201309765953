import React from 'react'
import { Space } from 'antd'

const MfeDisplay = (props) => {
  const { mfe, foldStart, foldEnd } = props

  const mfeDisplay = mfe ? parseFloat(mfe).toFixed(2) : "------"

  return (
    <div className="mfe-display">
      <Space direction="horizontal" align="end">
        <h2 className="mfe-amount">Nucleotides {foldStart}-{foldEnd}</h2>
        <h2 className="mfe-amount">{mfeDisplay} kcal/mol</h2>
      </Space>
    </div>
  )
}

export default MfeDisplay
