const stripNonBase = (input) => {
  const nonBaseRegex = /[^cgautCGAUT]/g
  return input.replace(nonBaseRegex, '')
}

const convertDNAtoRNA = (input) => {
  const thymine = /[tT]/g
  return input.replace(thymine, 'u')
}

export const cleanSequence = (input) => {
  let cleanedSequence = stripNonBase(input)
  cleanedSequence = convertDNAtoRNA(cleanedSequence)
  return cleanedSequence.toUpperCase()
}

export const formatDownloadText = data => {
  const { mfe, seq, ss } = data
  return `${seq}\n\n${ss}\n\n${mfe}`
}

// Firefox-only methods
const applyHighlights = (text, start, end) => {
  const linedText = text.replace(/\n$/g, '\n\n')
  return linedText.slice(0,start)
    + `<mark>${linedText.slice(start,end)}</mark>`
    + linedText.slice(end)
}

export const highlightRange = (inputText, selectStart, selectEnd) => {
  if (selectStart > -1 && selectEnd > -1 && selectEnd - selectStart > 0) {
    const highlightedText = applyHighlights(inputText, selectStart, selectEnd)
    document.getElementsByClassName("highlights")[0].innerHTML = highlightedText
  } else {
    document.getElementsByClassName("highlights")[0].innerHTML = inputText
  }
}
